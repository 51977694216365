import axios from '@axios'
import { ref, watch, computed } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useFileFolderDetails() {
  // Use toast
  const toast = useToast()

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const fileFolderName = router.currentRoute.params.name
  const fileFolderLabel = router.currentRoute.params.label

  const isLoading = ref(false)
  const searchQuery = ref('')
  const applicationsList = ref([])

  const fetchFileFolderDeatails = () => {
    const queryParams = {
      program_id: programId.value,
    }
    if (searchQuery.value) queryParams.user_name = searchQuery.value
    isLoading.value = true
    axios
      .get(`/auth/file-folders/${fileFolderName}`, {
        params: queryParams,
      })
      .then(response => {
        applicationsList.value = response.data.data
      })
      .catch(() => toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching File Folder',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      }))
      .finally(() => {
        isLoading.value = false
      })
  }

  watch([programId, searchQuery], () => {
    fetchFileFolderDeatails()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchFileFolderDeatails,
    isLoading,
    searchQuery,
    applicationsList,
    fileFolderLabel,
  }
}
