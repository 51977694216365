<template>
  <div>
    <h3 class="mb-1">
      {{ fileFolderLabel }}
    </h3>

    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Search</label>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search"
              class="w-25"
              debounce="500"
            />
          </b-col>
        </b-row>

      </div>
    </b-card>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="0.7"
      rounded="sm"
    >
      <b-row>
        <b-col
          v-for="application in applicationsList"
          :key="application.application_id"
          cols="4"
        >
          <b-overlay
            id="overlay-background"
            :show="isFileDownloadingForApp === application.application_id"
            variant="white"
            opacity="1"
            rounded="sm"
          >
            <b-card>
              <h4>
                <b-link
                  :to="{ name: 'admin-application-edit',
                         params: {
                           id: application.application_id,
                           type: getApplicationUserType(application.user_role)
                         }
                  }"
                >{{ application.full_name }}
                </b-link>
              </h4>
              <div class="text-muted my-1">
                File(s)
              </div>
              <div
                v-for="(file, index) in application.files"
                :key="index"
                class="list-style"
              >
                <div
                  :class="{'mb-1': application.files.length - 1 != index}"
                  class="d-flex align-items-center"
                >
                  <b-link
                    :href="file.url"
                    target="_blank"
                  >
                    {{ file.name }}
                  </b-link>
                  <feather-icon
                    size="18"
                    icon="DownloadIcon"
                    class="cursor-pointer ml-1 text-primary"
                    @click="downloadDocument(application.application_id, file)"
                  />
                </div>
              </div>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-overlay>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BLink, BOverlay,
} from 'bootstrap-vue'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import useFileFolderDetails from '@/views/admin/file-folders/file-folder-details/useFileFolderDetails'
import useUserDocuments from '@/views/components/user-documents/useUserDocuments'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BOverlay,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      isFileDownloadingForApp: null,
    }
  },
  setup() {
    const {
      fetchFileFolderDeatails,
      isLoading,
      searchQuery,
      applicationsList,
      fileFolderLabel,
    } = useFileFolderDetails()

    const {
      fetchFile,
    } = useUserDocuments()

    return {
      fetchFileFolderDeatails,
      isLoading,
      searchQuery,
      applicationsList,
      fileFolderLabel,
      fetchFile,
    }
  },
  created() {
    this.fetchFileFolderDeatails()
  },
  methods: {
    async downloadDocument(applicationId, file) {
      this.isFileDownloadingForApp = applicationId
      await this.fetchFile(file.path).then(response => {
        if (response) {
          const filename = `${file.name}(${file.user_name})`
          const fileExt = file.path.split('.').pop()
          this.downloadFile(response, filename.replace(/\s/g, '-'), fileExt)
        }
      }).finally(() => {
        this.isFileDownloadingForApp = null
      })
    },
    getApplicationUserType(role) {
      return role === 'child' ? this.camperStudent(1) : 'Staff'
    },
  },
}
</script>

<style lang="scss" scoped>
.user-name {
  font-size: 20px;
}
</style>
